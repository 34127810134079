<template>
  <div class="task-management px-4 mt-8 pb-32 mx-auto max-w-2xl">
    <vue-headful
      title="Tasks Management | Octoa"
    />

    <div class="flex justify-between items-center">
      <h1 class="text-black text-2xl">Tasks</h1>
      <button
        class="green-btn"
        @click="setMode(modes.ADD_TASK)"
      >
        New task
      </button>
    </div>

    <div class="mt-8 mb-4 flex justify-between items-center">
      <badge-select
        v-model="filter"
        :options="[
          { value: 'all', label: 'All' },
          { value: 'tasks', label: 'Tasks' },
          { value: 'actions', label: 'Actions' }
        ]"
      />

      <div class="table-component__filter">
        <input
          type="text"
          :value="search"
          placeholder="Search for a task or action"
          class="table-component__filter__field"
          @input="handleSearch"
        >
      </div>
    </div>

    <v-card
      v-if="loading"
      class="p-4 text-center mt-4"
    >
      <loader />
    </v-card>

    <v-card
      v-if="!loading && items.size === 0"
      class="p-4 text-center mt-4"
    >
      No items found.
    </v-card>

    <div class="overflow-x-auto overflow-y-visible pb-8">
      <table
        v-if="!loading && items.size > 0"
      >
        <thead>
          <tr>
            <th />
            <th>Tasks</th>
            <th>Project</th>
            <th class="hidden xl:table-cell">Phase</th>
            <th class="hidden xl:table-cell" />
            <th />
          </tr>
        </thead>
        <template
          v-for="[key, value] in paginated"
        >
          <tbody
            :key="key"
            class="tasks__group"
          >
            <tr>
              <td
                :rowspan="value.length + 1"
                class="align-top"
              >
                <due-badge
                  v-if="new Date(key).getTime() !== 0"
                  :date="new Date(key)"
                />
              </td>
            </tr>
            <tr
              v-for="item in value"
              :key="`${item.payload ? 'action' : 'task'}-${item.id}`"
            >
              <td class="tasks__name">
                <task-item
                  v-if="!item.payload"
                  :task="item"
                  @change="(completed) => handleCompleteTask(item, completed)"
                />
                <action-item
                  v-if="item.payload"
                  :action="item"
                  @click="handleTriggerAction(item)"
                />
              </td>
              <td>
                <template v-if="item.project">
                  <router-link class="text-green" :to="`projects/${item.project.id}`">{{ item.project.name }}</router-link>
                </template>
                <template v-else>-</template>
              </td>
              <td class="capitalize hidden xl:table-cell">{{ item.project ? item.project.state : '-' }}</td>
              <td class="hidden xl:table-cell">
                <action-progress
                  v-if="item.project"
                  :actions="item.project.phase_actions"
                />
              </td>
              <td class="tasks__actions text-right">
                <context-actions
                  v-if="item.payload"
                  :actions="actionActions(item)"
                />
                <context-actions
                  v-else
                  :actions="taskActions(item)"
                />
              </td>
            </tr>
          </tbody>
          <tbody
            :key="`${key}-separator`"
            class="task-separator"
          ><tr><td/></tr></tbody>
        </template>
      </table>
    </div>

    <div class="text-center mt-4">
      <button
        v-if="!loading && items.size > paginated.size"
        class="text-sm green border-b border-green"
        @click="page++"
      >
        Show more
      </button>
    </div>

    <slide-modal
      :open="[modes.ADD_TASK, modes.EDIT_TASK].includes(currentMode)"
      @background-click="resetMode"
    >
      <task-editor
        :task="currentTask"
        @deleted="handleUpdate"
        @updated="handleUpdate"
        @created="handleUpdate"
        @cancel="resetMode"
      />
    </slide-modal>

    <slide-modal
      :open="[modes.TRIGGER_ACTION].includes(currentMode)"
      @background-click="resetMode"
    >
      <action-editor
        :project="currentAction && currentAction.project"
        :action="currentAction"
        :to="currentAction && currentAction.project.contact.email"
        :template="currentAction && currentAction.payload.template_mail"
        :type="currentAction && getEmailType(currentAction)"
        @sent="handleUpdate"
        @cancel="resetMode"
        @delete="cancelAction(currentAction)"
      />
    </slide-modal>
  </div>
</template>

<script>
import posed, { PoseTransition } from 'vue-pose'
import { getTasks, deleteTask, completeTask } from '../api/Tasks'
import { format } from 'date-fns'
import ProgressDots from '../components/ProgressDots'
import DueBadge from '../components/DueBadge'
import ActionItem from '../components/Tasks/ActionItem'
import TaskItem from '../components/Tasks/TaskItem'
import ContextActions from '../components/Tasks/ContextActions'
import { deleteWorkflowJob } from '../api/WorkflowJobs'
import VCard from '../components/VCard/VCard'
import BadgeSelect from '../components/BadgeSelect'
import Loader from '../components/Loader'
import SlideModal from '../components/SlideModal'
import TaskEditor from '../components/Tasks/TaskEditor'
import auth from '../auth'
import ActionEditor from '../components/Tasks/ActionEditor'
import ActionProgress from '../components/ActionProgress'

export default {
  name: 'Tasks',
  components: { ActionProgress, ActionEditor, TaskEditor, SlideModal, Loader, BadgeSelect, VCard, ContextActions, TaskItem, ActionItem, DueBadge, ProgressDots },
  data () {
    return {
      user: auth.user(),
      items: new Map(),
      paginatedItems: new Map(),
      filter: 'all',
      search: '',
      searchTimer: null,
      perPage: 5,
      page: 1,
      loading: true,
      modes: {
        NONE: 'none',
        ADD_TASK: 'add-task',
        EDIT_TASK: 'edit-task',
        TRIGGER_ACTION: 'trigger-action'
      },
      currentMode: 'none',
      currentTask: null,
      currentAction: null,
      manualActionEmail: null
    }
  },
  computed: {
    paginated () {
      return this.paginate(this.items)
    }
  },
  watch: {
    filter () {
      this.loading = true
      this.page = 1
      this.fetchItems()
    }
  },
  mounted () {
    this.fetchItems()
  },
  methods: {
    setMode (mode, item = null) {
      this.currentMode = mode
      if (mode === this.modes.EDIT_TASK) this.currentTask = item
      if (mode === this.modes.TRIGGER_ACTION) this.currentAction = item
    },
    resetMode () {
      this.currentMode = this.modes.NONE
      this.currentTask = null
      this.currentAction = null
    },
    handleUpdate () {
      this.loading = true
      this.fetchItems()
      this.resetMode()
    },
    async fetchItems () {
      const res = this.handleErrors(await getTasks(this.filter, this.search))
      if (!res) return

      this.items = this.groupByDueDate(res.data)
      this.loading = false
    },
    paginate (items) {
      return new Map(
        [...items].slice(0, this.page * this.perPage)
      )
    },
    groupByDueDate (items) {
      const result = new Map()
      items.forEach(item => {
        const date = format(new Date(item.due_date), 'YYYY-MM-DD')
        if (!result.has(date)) {
          result.set(date, [])
        }
        result.get(date).push(item)
      })

      return new Map([...result].sort())
    },
    handleSearch (event) {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(() => {
        this.loading = true
        this.page = 1
        this.search = event.target.value
        this.fetchItems()
      }, 300)
    },
    async handleCompleteTask (task, completed) {
      const res = this.handleErrors(await completeTask(task.id, completed))
      if (res) {
        this.fetchItems()
      }
    },
    editTask (task) {
      this.setMode(this.modes.EDIT_TASK, task)
    },
    async deleteTask (task) {
      const canDelete = await this.$alert.confirm({
        title: 'Are you sure you want to delete this task?',
        text: 'If you delete this task, you will lose it\'s content.',
      })

      if (!canDelete) return

      const res = this.handleErrors(await deleteTask(task.id))
      if (res) {
        this.fetchItems()
      }
    },
    async cancelAction (action) {
      const canDelete = await this.$alert.confirm({
        title: 'Are you sure you want to cancel this action?',
      })

      if (!canDelete) return

      this.resetMode()

      const res = this.handleErrors(await deleteWorkflowJob(action.id))
      if (res) {
        this.fetchItems()
      }
    },
    async handleTriggerAction(action) {
      this.setMode(this.modes.TRIGGER_ACTION, action)
    },
    getEmailType(action) {
      if (action.payload.actionable_type === 'template-proposal') return 'proposal'
      if (action.payload.actionable_type === 'template-questionnaire') return 'questionnaire'
      return 'project'
    },
    taskActions (task) {
      return [
        {
          name: 'Edit',
          action: () => this.editTask(task)
        },
        {
          name: 'Delete',
          action: () => this.deleteTask(task)
        }
      ]
    },
    actionActions (action) {
      return [
        {
          name: 'Cancel',
          action: () => this.cancelAction(action)
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.table-component__filter {
  width: 245px;
}

table {
  @apply border-separate w-full text-grey-darker whitespace-no-wrap;
  border-spacing: 0;
}

tbody {
  @apply w-full;
}

th {
  @apply p-4;
  text-align: left;
}

td {
  @apply px-4 py-5;
}

.tasks__group {
  td {
    @apply bg-white;
  }

  tr td:last-child {
    @apply border-r border-grey-lighter;
  }

  tr:first-of-type td:first-of-type {
    @apply rounded-l border border-r-0 border-grey-lighter;
  }

  tr:nth-child(2) td {
    @apply border-t border-grey-lighter;
  }

  tr td.tasks__name,
  tr td.tasks__actions, {
    @apply py-0;
  }

  tr:nth-child(2) td:last-child {
    @apply rounded-tr;
  }

  tr:last-child td {
    @apply border-b border-grey-lighter;
  }

  tr:last-child td:last-child {
    @apply rounded-br;
  }
}

.task-separator {
  td {
    padding: calc(1rem / 2) 0;
  }
}
</style>
