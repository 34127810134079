<template>
  <div>
    <button
      v-for="option in options"
      :key="option.value"
      :class="{
        'bg-white border rounded-full text-xs px-3 py-1.5 mr-1.5 transition': true,
        'border-grey-lighter text-grey-semi-light': value !== option.value,
        'border-grey text-grey-darker font-bold': value === option.value
      }"
      @click="handleClick(option.value)"
    >
      {{ option.label }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'BadgeSelect',
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: [String, Number, Boolean],
      required: true
    }
  },
  methods: {
    handleClick(value) {
      this.$emit('input', value)
    }
  },
}
</script>

<style scoped>

</style>
