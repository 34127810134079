<template>
  <div>
    <h2 class="font-bold">
      <template v-if="task">Edit a task</template>
      <template v-else>Add a task</template>
    </h2>

    <div class="flex px-2 mt-12">
      <div class="w-full">
        <div class="mt-2">
          <div class="input-group">
            <div v-if="task !== null">
              <v-checkbox
                id="taskIsCompleted"
                v-model="taskIsCompleted"
                :checked="taskIsCompleted"
                class="mb-8"
                label="Mark task as completed"
                name="is_completed"
              />
            </div>
            <label>Task description</label>
            <textarea
              v-model="taskName"
              v-validate="{ required: true }"
              class="no-min-height"
              name="description"
              placeholder="Write your task description"
            />
            <span
              v-if="errors.first('description')"
              class="error"
            >{{ errors.first('description') }}.</span>
          </div>

          <div class="input-group">
            <label>Choose a due date (optional)</label>
            <v-date-picker
              class="datepicker"
              v-model="taskDueDate"
              :popover="{ visibility: 'click' }"
              :masks="{L: 'WWW, MMM D, YYYY'}"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  name="due_date"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>

          <div class="mt-2 input-group ">
            <label>Link to a project (optional)</label>
            <autocomplete
              ref="projectField"
              name="project"
              :search="searchProject"
              :get-result-value="getProjectResult"
              :debounce-time="500"
              @submit="searchProjectClick"
            />
          </div>
        </div>
        <div class="border-t border-grey-lighter mt-8 -mx-10 pt-4 px-10">
          <button
            v-if="task !== null"
            class="underline text-red mr-4"
            @click="deleteTask"
          >
            Delete
          </button>
          <button
            class="grey-btn mr-4"
            @click="$emit('cancel')"
          >
            Cancel
          </button>
          <button
            v-if="task === null"
            class="green-btn"
            @click="createTask"
          >
            Add task
          </button>
          <button
            v-if="task !== null"
            class="green-btn"
            @click="updateTask"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createTask, deleteTask, updateTask } from '../../api/Tasks'
import { parseFromTimeZone } from 'date-fns-timezone'

export default {
  name: 'TaskEditor',
  emits: ['deleted', 'updated', 'created', 'cancel'],
  props: {
    task: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    let dueDate = this.task ? this.task.due_date : null
    dueDate = new Date(dueDate).getTime() === 0 ? null : dueDate

    return {
      selectedProject: this.task ? this.task.project : null,
      taskDueDate: dueDate,
      taskName: this.task ? this.task.name : '',
      taskIsCompleted: this.task ? this.task.is_completed : false,
    }
  },
  mounted() {
    if (this.task) {
      this.$refs.projectField.setValue(this.task.project)
    }
  },
  methods: {
    async searchProject(input) {
      if (input.length < 1) { return [] }
      this.selectedProject = null
      try {
        const { data } = await this.$api.get('projects').search(input)
        const { results } = data
        return results
      } catch(e){
        logException(e)
      }
    },
    getProjectResult(result){
      return result.name
    },
    searchProjectClick(result) {
      this.selectedProject = result
    },
    async createTask() {
      const canPass = await this.$validator.validate()
      if (!canPass) return

      const data = {
        projectId: (this.selectedProject) ? this.selectedProject.id : null,
        name: this.taskName,
        dueDate: parseFromTimeZone(this.taskDueDate, { timeZone: 'Etc/UTC' }),
      }

      const res = this.handleErrors(await createTask(data))
      if (res) {
        this.handleGamification()
        this.$emit('created', res)
        this.$toasted.global.general_success({
          message : 'Task created successfully.'
        })
      }
    },
    async handleGamification() {
      try {
        await this.$store.dispatch('company/gamification', {
          entryType: 'add_first_tasks',
        })
      } catch(e) {
        this.$toasted.global.api_error(e)
      }
    },
    async updateTask() {
      const data = {
        is_completed: this.taskIsCompleted,
        project_id: (this.selectedProject) ? this.selectedProject.id : null,
        name: this.taskName,
        due_date: this.taskDueDate,
      }

      const res = this.handleErrors(await updateTask(this.task.id, data))

      if (res) {
        this.$emit('updated', this.task)
        this.$toasted.global.general_success({
          message : 'Task updated successfully.'
        })
      }
    },
    async deleteTask() {
      const canDelete = await this.$alert.confirm({
        title: 'Are you sure you want to delete this task?',
        text: 'If you delete this task, you will lose it\'s content.',
      })

      if (!canDelete) return

      const res = deleteTask(this.task.id)

      if (res) {
        this.$emit('deleted')
      }
    }
  }
}
</script>

<style scoped>

</style>
