<template>
  <div class="custom-checkbox">
    <div class="flex items-center custom-checkbox">
      <input
        :id="`complete-${task.id}`"
        class="styled-checkbox"
        type="checkbox"
        v-model="checked"
        @change="handleChange"
      >
      <label
        :class="{
          'flex items-center': true,
          'line-through': checked
        }"
        :for="`complete-${task.id}`"
      >
        {{ task.name }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TaskItem',
  emits: ['change'],
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      checked: this.task.is_completed
    }
  },
  methods: {
    handleChange(event) {
      this.$emit('change', event.target.checked)
    }
  },
}
</script>

<style scoped>
  .custom-checkbox .styled-checkbox + label::before {
    @apply mr-4;
  }
</style>
